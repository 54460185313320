/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { Email } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import Address from './Address'
import CustomerStatusBadge from './CustomerStatusBadge'
import EmailModal from './EmailModal'

type CustomerDataProps = {
  customer: any
}

const CustomerData = ({ customer }: CustomerDataProps) => {
  const [status, setStatus] = useState<string>(
    JSON.stringify(customer.billingAddress) === JSON.stringify(customer.shippingAddress)
      ? 'Same as shipping'
      : '',
  )
  const [loyaltyStatus, setLoyaltyStatus] = useState<string>('')
  const [customerStatus, setCustomerStatus] = useState<string>('')
  const [customerInfoStatus, setCustomerInfoStatus] = useState<string>('')
  const [isEmailModalVisible, setEmailModalVisibility] = useState<boolean>(false)
  const order = useSelector((state: any) => state.order.value)

  useEffect(() => {
    if (Object.values(customer).some((element) => element === null)) {
      setCustomerInfoStatus('No customer info provided')
    }
    if (Array.isArray(customer?._customer.tags)) {
      getLoyaltyStatus(customer?._customer.tags)
      getTrustedStatus(customer?._customer.tags)
    }
  }, [])

  const handleEmailModalVisibility = () => {
    setEmailModalVisibility(!isEmailModalVisible)
  }

  const getLoyaltyStatus = (tags: string[]) => {
    let tmpStatus = ''
    if (tags.includes('loyalty_vip')) {
      tmpStatus = 'ONYX'
    } else if (tags.includes('loyalty_member') && !tags.includes('loyalty_vip')) {
      tmpStatus = 'MARBLE'
    }
    setLoyaltyStatus(tmpStatus)
  }

  const getTrustedStatus = (tags: string[]) => {
    let tmpCustomerStatus = 'Trust'
    if (tags.includes('IncompleteRMA')) {
      tmpCustomerStatus = 'IncompleteRMA'
    }
    setCustomerStatus(tmpCustomerStatus)
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      spacing={4}
    >
      <EmailModal
        isShown={isEmailModalVisible}
        onClose={handleEmailModalVisibility}
        order={order}
        customer={customer}
      />
      <Grid item xs={6} md={4}>
        <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Grid>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='h2' fontSize={20} fontWeight={'bold'}>
                  Customer info
                </Typography>
                {loyaltyStatus !== '' && (
                  <CustomerStatusBadge status={loyaltyStatus} type={'Loyalty'} />
                )}
                <CustomerStatusBadge status={customerStatus} type={customerStatus} />
                <IconButton
                  onClick={handleEmailModalVisibility}
                  sx={{ padding: '0px 0px 6px 0px' }}
                >
                  <Email />
                </IconButton>
              </Stack>
            </Grid>
            {customerInfoStatus ? (
              <Grid>{customerInfoStatus}</Grid>
            ) : (
              <Grid>
                <Grid sx={{ wordBreak: 'break-all' }}>{customer?._name}</Grid>
                <Grid>{customer?._phone}</Grid>
                <Grid>{customer?._email}</Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={4}>
        <Address title='Shipping address' address={customer._shippingAddress} customer={customer} />
      </Grid>
      <Grid item xs={6} md={4}>
        <Address
          title='Billing address'
          address={customer._billingAddress}
          customer={customer}
          status={status}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerData
